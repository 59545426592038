import { MutableRefObject, RefObject } from "react"
import { AlignmentType } from "./components/ZoomComponent"

export const DEFAULT_MARGIN = 10

export interface PragmaPdfViewerProps {
  file: string
  fileName?: string
  isOpenRemarkForm: boolean
  actionButtonRemark?: (page: number, numPages: number) => void
}

export interface LazyPageProps {
  pageIndex: number
  scale: number
  currentPage: number
  defaultHeight: number
  scrollContainerRef: RefObject<HTMLDivElement>
  widthAlignment: AlignmentType
  rotate: number
  handTool: boolean
  pageData: MutableRefObject<PageData[]>
}

export interface PageData {
  pageNumber: number
  width: number
  height: number
}

export type NoteMode = 'arrow' | 'rectangle' | 'highlight' | 'text'

export interface AnnotationRects {
  left: number
  top: number
  width: number
  height: number
}

export interface Annotation {
  rects: AnnotationRects
  rotate: number
  text?: string
  type: NoteMode
}