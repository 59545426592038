import ImportExportIcon from '@mui/icons-material/ImportExport'
import MobiledataOffIcon from '@mui/icons-material/MobiledataOff'
import { Box, Checkbox } from '@mui/material'
import { ChangeEvent, useMemo, useState } from 'react'

import {
  useDeleteInnermapDepartmentMutation,
  useDeleteInnermapMemberMutation,
  useGetInnermapMembersQuery,
  useMoveInnermapDepartmentMutation,
  useMoveInnermapMemberMutation,
  useSendMemberActionInternalAgreementMutation,
} from '../../../../../api/internalAgreement'
import {
  InnermapActionsType,
  InnermapMember,
  MoveInnermapDepartment,
  MoveInnermapMember,
} from '../../../../../api/internalAgreement/types'
import Tooltip from '../../../../../components/Tooltip'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../../hooks/useConfirmDialog'
import { setOpenedDrawer } from '../../../../../store/slices/documentsPages/drawerInfo'
import { docsSelector } from '../../../../../store/slices/documentsPages/tom'
import {
  TableFormValues,
  approvedProcedureSelector,
  currentSchemaSelector,
  setIsOpenFormAddMember,
  setTableFormValues,
} from '../../../../../store/slices/internalAgreement'
import { setRemarkFormData } from '../../../../../store/slices/remarks/remarks'
import { RemarkFormData } from '../../../../../store/slices/remarks/remarks.types'
import { useAppDispatch, useTypedSelector } from '../../../../../store/store'
import { useAccess } from '../../InternalAgreementSchemes.utils'
import { HeaderRow, StyledTitle, TableWrapper } from './InternalAgreementTable.styles'
import { InternalAgreementTableProps } from './InternalAgreementTable.types'
import { columns } from './InternalAgreementTable.utils'
import { DepartmentRow } from './components/DepartmentRow'
import { MemberRow } from './components/MemberRow'

export const InternalAgreementTable = ({ projectId, innermapId, isSelectedProcedure }: InternalAgreementTableProps) => {
  const dispatch = useAppDispatch()
  const { isAdmin, isModerator } = useAccess(projectId)
  const { data: innermapMembers } = useGetInnermapMembersQuery({ projectId, innermapId })
  const { data: innermapMembersData } = innermapMembers || {}

  const [checked, setChecked] = useState(false)
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => setChecked(e.target.checked)

  const approvedProcedures = useTypedSelector(approvedProcedureSelector)
  const currentSchema = useTypedSelector(currentSchemaSelector)
  const { tomVersions, tomChanges } = useTypedSelector(docsSelector)

  const isApproved = approvedProcedures.includes(currentSchema.id)
  const [moveInnermapDepartment] = useMoveInnermapDepartmentMutation()
  const [moveInnermapMember] = useMoveInnermapMemberMutation()
  const [deleteInnermapDepartment] = useDeleteInnermapDepartmentMutation()
  const [deleteInnermapMember] = useDeleteInnermapMemberMutation()
  const [sendMemberActionInternalAgreement] = useSendMemberActionInternalAgreementMutation()

  const moveUpDepartment = (values: MoveInnermapDepartment) =>
    moveInnermapDepartment({ projectId, innermapId, ...values })
  const moveDownDepartment = (values: MoveInnermapDepartment) =>
    moveInnermapDepartment({ projectId, innermapId, ...values })

  const moveUpMember = (values: MoveInnermapMember) => moveInnermapMember({ projectId, innermapId, ...values })
  const moveDownMember = (values: MoveInnermapMember) => moveInnermapMember({ projectId, innermapId, ...values })

  const deleteMember = (memberId: number) => deleteInnermapMember({ projectId, innermapId, memberId })
  const deleteDepartment = (confirm: boolean, department: string) => {
    if (confirm) {
      deleteInnermapDepartment({ projectId, innermapId, department })
    }
  }

  const sendMemberAction = (memberId: number, action: InnermapActionsType) => {
    if (action === 'remarks') {
      dispatch(setOpenedDrawer({ openedDrawer: 'remarks' }))
      dispatch(
        setRemarkFormData({
          remarkFormData: {
            page: null,
            pages: null,
            change: null,
            version: tomVersions[0].version,
          } as RemarkFormData,
        }),
      )
    } else {
      sendMemberActionInternalAgreement({
        projectId,
        innermapId,
        memberId,
        action,
      })
    }
  }

  const editMember = (member: InnermapMember) => {
    dispatch(setIsOpenFormAddMember({ isOpenFormAddMember: { isOpen: true, formMode: 'edit' } }))
    dispatch(setTableFormValues({ tableFormValues: member }))
  }

  const openForm = (department: string) => {
    dispatch(setIsOpenFormAddMember({ isOpenFormAddMember: { isOpen: true, formMode: 'create' } }))
    dispatch(setTableFormValues({ tableFormValues: { ...({} as TableFormValues), department } }))
  }

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm: deleteDepartment,
    title: 'Удалить подразделение?',
    body: <>Вы уверены, что хотите удалить подразделение и всех его участников? Это действие нельзя отменить</>,
    denyButtonText: 'Отменить',
    confirmButtonColor: 'error',
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const headerColumns = useMemo(() => {
    const updatedColumns = [...columns]
    const lastColumn = updatedColumns[updatedColumns.length - 1]
    if (isApproved) {
      lastColumn.minwidth = '36px'
      return updatedColumns
    }
    if (isModerator || isAdmin) {
      lastColumn.minwidth = '64px'
      return updatedColumns
    } else {
      lastColumn.minwidth = '36px'
      return updatedColumns
    }
  }, [isAdmin, isModerator, isApproved])

  return (
    <TableWrapper>
      <HeaderRow direction='row' spacing={1}>
        {(isAdmin || isModerator) && !isApproved ? (
          <Tooltip title={<>Сортировка</>}>
            <Checkbox
              size='small'
              icon={<MobiledataOffIcon />}
              checkedIcon={<ImportExportIcon />}
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{ marginRight: checked ? '30px' : 0 }}
            />
          </Tooltip>
        ) : (
          <Box minWidth='34px' />
        )}

        {headerColumns.map((item, index) => (
          <Box
            key={index}
            minWidth={item.minwidth}
            padding={item.padding}
            width={item.width}
            marginLeft={item.marginLeft}
          >
            <StyledTitle variant='body2' textAlign={item.textAlign}>
              {item.title}
            </StyledTitle>
          </Box>
        ))}
      </HeaderRow>
      {innermapMembersData?.map((list) => (
        <>
          <DepartmentRow
            key={list.department}
            isApproved={isApproved}
            department={list.department}
            isFirst={list.order === 1}
            isLast={list.order === innermapMembersData.length}
            allowMove={checked}
            deleteDepartment={openConfirm}
            moveUpDepartment={moveUpDepartment}
            moveDownDepartment={moveDownDepartment}
            openForm={openForm}
          />
          {list.members.map((member) => (
            <MemberRow
              key={member.id}
              allowMove={checked}
              isApproved={isApproved}
              member={member}
              isFirst={member.order === 1}
              isLast={member.order === list.members.length}
              isSelectedProcedure={isSelectedProcedure}
              editMember={editMember}
              deleteMember={deleteMember}
              moveUpMember={moveUpMember}
              moveDownMember={moveDownMember}
              sendMemberAction={sendMemberAction}
            />
          ))}
        </>
      ))}
      <ConfirmDialog />
    </TableWrapper>
  )
}
