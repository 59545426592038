import styled from "styled-components";
import { colorNotes } from "../NotesToolbar";

interface TextBoxProps {
  left: number
  top: number
  width: number
  height: number
  scale: number
}

export const TextBox = styled.textarea<TextBoxProps>`
  position: absolute;
  left: ${props => props.left + 'px'};
  top: ${props => props.top + 'px'};
  width: ${props => props.width + 'px'};
  height: ${props => props.height + 'px'};
  font-size: ${props => 16 * props.scale + 'px'};
  font-family: 'Roboto';
  line-height: 1.2;
  color: ${colorNotes['red']};
  background-color: transparent;
  padding: ${props => 6 * props.scale + 'px'};
  padding-left: ${props => 5.5 * props.scale + 'px'};
  border: none;
  outline: none;
  resize: none;
`