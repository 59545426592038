import { memo, useCallback, useEffect, useState } from "react"
import { LazyPageProps } from "./LazyPage.types"
import { PageContainer } from "../../PragmaPdfViewer.styles"
import { Page } from "react-pdf"
import { Placeholder } from "../Placeholder"
import { RectangleTool } from "../RectangleTool/RectangleTool"
import { Annotation } from "../../PragmaPdfViewer.types"
import { CanvasAnnotations } from "../CanvasAnnotations"

export const LazyPage = memo(({
  currentPage,
  defaultHeight,
  handTool,
  pageData,
  pageIndex,
  rotate,
  scale,
  scrollContainerRef,
  widthAlignment,
  selectedNoteMode,
  setPageData
}: LazyPageProps) => {

  const [annotations, setAnnotations] = useState<Annotation[]>([])
  const changeAnnotations = (annotations: Annotation | Annotation[]) => {
    Array.isArray(annotations) ? setAnnotations(annotations) : setAnnotations((prev) => [...prev, annotations])
  }

  const handlePageRenderSuccess = (
    pageNumber: number,
    width: number,
    height: number,
  ) => {
    const existingPageData = pageData.find((data) => data.pageNumber === pageNumber)
    if (!existingPageData) {
      setPageData({
        pageNumber,
        width: width * (1 / scale),
        height: height * (1 / scale)
      })
    }
  }



  const placeholderSize = (): { width: number, height: number } | undefined => {
    const currentPageData = pageData.find(data => data.pageNumber === pageIndex + 1)

    const pageWidth = currentPageData
      ? currentPageData.width * scale
      : pageData.length > 0
        ? pageData[0].width * scale
        : undefined

    const pageHeight = currentPageData
      ? currentPageData.height * scale
      : pageData.length > 0
        ? pageData[0].height * scale
        : undefined

    if (pageWidth && pageHeight) {
      if (rotate === 270 || rotate === 90) {
        return { width: pageHeight, height: pageWidth }
      }
      return { width: pageWidth, height: pageHeight }
    }
  }

  const placeholderPageSize = placeholderSize()

  if (pageIndex === currentPage - 3 || pageIndex === currentPage - 2 || pageIndex === currentPage - 1 || pageIndex === currentPage || pageIndex === currentPage + 1) {
    return (
      <PageContainer data-page={pageIndex} >
        <Page
          renderTextLayer={!handTool}
          width={widthAlignment === 'width' ? scrollContainerRef.current?.offsetWidth! - 33 : undefined}
          height={widthAlignment === 'height' ? defaultHeight - 18 : undefined}
          scale={scale}
          loading={null}
          pageNumber={pageIndex + 1}
          onRenderSuccess={({ pageNumber, width, height }) => handlePageRenderSuccess(pageNumber, width, height)}
        /* onGetAnnotationsSuccess={(annotations) => console.log(annotations)} */
        />
        {/* {placeholderPageSize && selectedNoteMode === 'rectangle' &&
          <RectangleTool
            width={placeholderPageSize.width}
            height={placeholderPageSize.height}
            rotate={rotate}
            scale={scale}
            annotationType={selectedNoteMode}
            annotations={annotations}
            setAnnotations={changeAnnotations}
          />} */}
        {placeholderPageSize &&
          <CanvasAnnotations
            width={placeholderPageSize.width}
            height={placeholderPageSize.height}
            rotate={rotate}
            scale={scale}
            annotationType={selectedNoteMode}
            annotations={annotations}
            setAnnotations={changeAnnotations}
          />}

      </PageContainer>
    )
  }

  return (
    <PageContainer data-page={pageIndex}>
      {placeholderPageSize && <Placeholder height={placeholderPageSize.height} width={placeholderPageSize.width} />}
    </PageContainer>
  )
})