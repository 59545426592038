import { FC, useCallback, useEffect, useState } from "react";
import { IRemarkEliminationHistoryProps, anchorOriginStatusMenu, transformOriginStatusMenu } from "./RemarkEliminationHistory.types";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTypedSelector } from "store/store";
import { remarkHistoryDataSelector } from "store/slices/remarks/selectors/remarks.selectors";
import { useDownloadRemarkAttachmentMutation, useGetRemarkOnIdQuery } from "api/remarks";
import { profileSelector } from "store/slices/profile";
import { RemarkMenu, RemarkMenuData } from "pages/DocView/components/DocViewRightSideBar/components/RemarkMenu";
import { MAX_FILENAME_LENGTH, availableStatuses, titleStatus } from "pages/DocView/components/DocViewRightSideBar/components/Remark/Remark.config";
import { RemarkStatus } from "api/remarks/types";
import { ChangeStatusButton, EllipsisText, FileNameText, MainText, MessageWrapper, RemarkStatusRu, RemarkStatusLabel, StatusLabel, StatusText, SubtitleText, remarkColorStatus, remarkStatusEnToRu } from "pages/DocView/components/DocViewRightSideBar/components/Remark";
import { formatName } from "utils/formatName";
import { convertDateTime } from "utils/formatDateAndTimeGMT";
import { RemarkHistoryContainer, ReplyContainer } from "./RemarkEliminationHistory.styles";
import { ReplyToRemark } from "pages/DocView/components/DocViewRightSideBar/components/ReplyToRemark";
import { Download as DownloadIcon } from '@mui/icons-material';
import MailIcon from '@mui/icons-material/Mail';
import Tooltip from "components/Tooltip";
import { reduceFileName } from "utils/reduceFileName";
import { downloadBlob } from "utils/dowloadBlob";
import { CircleInfoText } from "@pages/DocView/components/DocViewRightSideBar/components/RemarkForm";

export const RemarkEliminationHistory: FC<IRemarkEliminationHistoryProps> = ({ remarkResponse, onFormChange }) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  const { canAddMessage, messages, remark } = remarkResponse || {}
  const { avatar, id, name } = remark?.author || {}
  const profile = useTypedSelector(profileSelector)
  const initialRemark: string = remark?.isSecond ? 'Повторное' : 'Первичное'
  const isAdmin = profile.role === 'admin'
  const isEmployeResponsible = profile.company.userCompanyName === remark?.responsible
  const isAuthor = profile.employeeId === id
  const { enqueueSnackbar } = useSnackbar()

  const [downloadAttachment, downloadAttachmentResponse] = useDownloadRemarkAttachmentMutation()

  const [fileDataForDownload, setFileDataForDownload] = useState<string | null>(null)
  const [status, setStatus] = useState<RemarkStatus | null>(null)

  useEffect(() => {
    if (downloadAttachmentResponse.data) {
      downloadBlob(downloadAttachmentResponse.data, fileDataForDownload || 'Файл загрузки')
    }
    if (downloadAttachmentResponse.isError) {
      enqueueSnackbar('Не удалось загрузить файл, попробуйте еще раз.', { variant: 'error' })
    }
  }, [downloadAttachmentResponse])

  const statusFunctions: Record<RemarkStatusRu, () => void> = {
    'Направлено': () => setStatus('submitted'),
    'В работе': () => setStatus('accepted'),
    'Отклонено': () => setStatus('rejected'),
    'Устранено': () => setStatus('resolved'),
    'Закрыто': () => setStatus('closed'),
    'Снято': () => setStatus('cancelled'),
    'Создано': () => setStatus('created')
  }

  const statuses =
    isAdmin ? availableStatuses.admin
      : isAuthor ? availableStatuses.author
        : isEmployeResponsible ? availableStatuses.responsible
          : undefined

  const statusMenuData: RemarkMenuData[] | undefined =
    remark && statuses?.[remark.status]?.map((status) => ({
      itemName: titleStatus[status],
      action: statusFunctions[status],
    }))

  const remarkTomInfoTitle = (page: number | null, version: number | null, change: number | null, withList: boolean) =>
    [
      page && `Лист ${page}`,
      version !== null && `версия тома ${version}`,
      change !== null && `изм. ${change}`
    ].filter(Boolean).join(', ')

  const handleDownloadAttachment = (attachId: number, fileName: string) => {
    downloadAttachment({ projectId, remarkId: remark!.id, attachId })
    setFileDataForDownload(fileName)
  }

  return (
    <>
      <Stack overflow={'auto'} height={'100%'}>
        {remark && messages ? (
          <>
            {!canAddMessage || status ? null : (
              <Stack padding={'0 20px 20px 20px'}>
                <RemarkMenu
                  data={statusMenuData}
                  title="Статус замечания"
                  anchorOrigin={anchorOriginStatusMenu}
                  transformOrigin={transformOriginStatusMenu}
                  menuWidth="360px"
                >
                  <Stack component={ChangeStatusButton} direction='row' spacing={1.5}>
                    <Avatar src={profile.avatar} sx={{ height: '32px', width: '32px' }} />
                    <Box textAlign="start">
                      <EllipsisText variant="body1" fontWeight={500} lineHeight='normal' maxWidth='240px'>
                        {formatName(profile.lastName, profile.firstName, profile.middleName)}
                      </EllipsisText>
                      <Typography variant="body2" fontSize={12}>
                        {`Нажмите для ${messages?.length === 1 ? 'установки' : 'изменения'} статуса`}
                      </Typography>
                    </Box>
                  </Stack>
                </RemarkMenu>
              </Stack>
            )}

            <RemarkHistoryContainer>
              <>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <StatusLabel color={remarkColorStatus[remark.status]} />
                  <StatusText>{remarkStatusEnToRu[remark.status]}, {convertDateTime(remark.updatedAt, false, true)}</StatusText>
                </Stack>

                <Stack spacing={2} pt={2}>
                  {remark.responsible &&
                    <Stack direction='row' justifyContent='space-between' spacing={1}>
                      <SubtitleText>Ответственный: </SubtitleText>
                      <Typography variant="h1" fontSize={12}>{remark.responsible}</Typography>
                    </Stack>}
                  {status &&
                    <ReplyContainer m={2.5}>
                      <ReplyToRemark
                        newStatus={status}
                        remarkId={remark.id}
                        closeReply={() => setStatus(null)}
                        onFormChange={onFormChange}
                      />
                    </ReplyContainer>
                  }
                  {messages.map(({ message, attachments, mail }, index) => (
                    <Stack key={message.id}>
                      {message.status === 'submitted' &&
                        <Stack py={1} mb={2} direction='row' alignItems='center' justifyContent='space-between'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="7" viewBox="0 0 30 7" fill="none">
                            <path d="M5 3L0 0.613249V6.38675L5 4V3ZM4.5 4H30V3H4.5V4Z" fill="#7890B2" />
                          </svg>
                          <StatusText>{index === messages.length - 1 ? 'Направлено ' : 'Направлено повторно '} {convertDateTime(message.createdAt, true, true)}</StatusText>
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="7" viewBox="0 0 30 7" fill="none">
                            <path d="M25 4L30 6.38675V0.613249L25 3V4ZM0 4H25.5V3H0V4Z" fill="#7890B2" />
                          </svg>
                        </Stack>
                      }
                      <MessageWrapper spacing={1} $bg={message.status === 'created' || message.status === 'submitted' ? 'white' : 'shades'}>
                        {index === messages.length - 1 ? (
                          <Stack direction='row' spacing={1.5}>
                            <Avatar src={avatar || ''} sx={{ height: '32px', width: '32px' }} />
                            <Stack sx={{ textAlign: 'start' }} flex={1}>
                              <Typography variant="body1" fontWeight={500} lineHeight='normal'>{name}</Typography>
                              <Typography variant="body1" fontSize={14}>
                                {remarkTomInfoTitle(remark.page, message.version, remark.change, true) || 'замечание к предыдущей версии'}
                              </Typography>
                              <Typography variant="body1" fontSize={14}>
                                {initialRemark}, {convertDateTime(remark.createdAt, false, true)}
                              </Typography>
                            </Stack>
                            {mail &&
                              <Tooltip variant="light" title={<></>} customTitle={
                                <Stack direction='row' spacing={0.5}>
                                  <Typography variant='tooltip' textTransform='none' color='#5C6E8C'>Письмо:</Typography>
                                  <Typography variant='tooltip' textTransform='none' fontWeight={500}>
                                    {`${mail.mailNum} от ${mail.mailDate}`}
                                  </Typography>
                                </Stack>
                              }>
                                <MailIcon fontSize="small" color="primary"
                                  sx={{ position: 'relative', right: '7px' }} />
                              </Tooltip>
                            }
                          </Stack>
                        ) : (
                          <Stack direction='row' spacing={1.5}>
                            <Avatar src={message.author.avatar || ''} sx={{ height: '32px', width: '32px' }} />
                            <Box textAlign="start" flex={1}>
                              <Typography variant="body1" fontWeight={500} lineHeight='normal'>{message.author.name}</Typography>
                              <Typography variant="body1" fontSize={14}>
                                {remarkTomInfoTitle(remark.page, message.version, remark.change, false)}
                              </Typography>
                              <Stack direction='row' spacing={1} alignItems='center'>
                                <RemarkStatusLabel color={remarkColorStatus[message.status]}></RemarkStatusLabel>
                                <Typography variant="body1" fontSize={14}>
                                  {`${remarkStatusEnToRu[message.status]} ${convertDateTime(message.createdAt, false, true)}`}
                                </Typography>
                              </Stack>
                            </Box>
                            {mail &&
                              <Tooltip variant="light" title={<></>} customTitle={
                                <Stack direction='row' spacing={0.5}>
                                  <Typography variant='tooltip' textTransform='none' color='#5C6E8C'>Письмо:</Typography>
                                  <Typography variant='tooltip' textTransform='none' fontWeight={500}>
                                    {`${mail.mailNum} от ${mail.mailDate}`}
                                  </Typography>
                                </Stack>
                              }>
                                <MailIcon fontSize="small" color="primary"
                                  sx={{ position: 'relative', right: '7px' }} />
                              </Tooltip>
                            }
                          </Stack>)}

                        {message.comment &&
                          <MainText variant="body1">
                            {message.comment}
                          </MainText>}
                        <Stack direction='row' alignItems='center' justifyContent='space-between'>
                          <SubtitleText>Пометки</SubtitleText>
                          <CircleInfoText>
                            <Typography>3</Typography>
                          </CircleInfoText>
                        </Stack>
                        {message.reason &&
                          <Stack alignItems='flex-start'>
                            <SubtitleText>Основание</SubtitleText>
                            <MainText variant="body1">
                              {message.reason}
                            </MainText>
                          </Stack>}

                        {attachments.map((file) => (
                          <Stack direction='row' alignItems='center' spacing={1} key={file.id} >
                            <Box display="inline-flex" alignItems="center"
                              sx={{ cursor: 'pointer' }} onClick={() => handleDownloadAttachment(file.id, file.name)}>
                              <DownloadIcon fontSize="small" color="primary" sx={{ marginRight: 1 }} />
                              <Tooltip
                                title={<>{file.name}</>}
                                disableHoverListener={file.name.length < MAX_FILENAME_LENGTH}>
                                <FileNameText>{reduceFileName(file.name, MAX_FILENAME_LENGTH)}</FileNameText>
                              </Tooltip>
                            </Box>
                          </Stack>
                        ))}

                      </MessageWrapper>
                    </Stack>
                  ))}
                </Stack>
              </>
            </RemarkHistoryContainer>
          </>
        ) : (
          <Stack height={'100%'} justifyContent={'center'} alignItems={'center'}>
            <Typography variant="body2">История устранений отсутствует.</Typography>
          </Stack>
        )}
      </Stack>
    </>
  )
}