import { Avatar, Box, CircularProgress, IconButton, Stack, Typography } from "@mui/material"
import { EllipsisText, FormButton, ReplyBackground, StyledButton, SubtitleText } from "./ReplyToRemark.styles"
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from "react"
import Tooltip from "../../../../../../components/Tooltip";
import { ReplyMessageValues, ReplyToRemarkProps } from "./ReplyToRemark.types";
import { Form, FormikProvider } from "formik";
import { useForm } from "../../../../../../hooks/useForm";
import { useAddRemarkMessageMutation } from "../../../../../../api/remarks";
import { useParams } from "react-router-dom";
import { RemarkStatusLabel, remarkColorStatus, remarkStatusEnToRu } from "../Remark";
import { useAppDispatch, useTypedSelector } from "../../../../../../store/store";
import { profileSelector } from "../../../../../../store/slices/profile";
import { formatName } from "../../../../../../utils/formatName";
import { setAllowScrolling } from "../../../../../../store/slices/remarks/remarks";
import { onDrawerClose } from "../../../../../../store/slices/documentsPages/drawerInfo";
import { useSnackbar } from "notistack";
import { RemarkTextField } from "../RemarkForm";
import { FileUploader } from "../FileUploader/FileUploader";

export const ReplyToRemark = ({ closeReply, newStatus, remarkId, onFormChange }: ReplyToRemarkProps) => {

  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const profile = useTypedSelector(profileSelector)
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const [openReasonField, setOpenReasonField] = useState<boolean>(false)
  const [addRemarkMessage, addRemarkMessageResponse,] = useAddRemarkMessageMutation()


  const initialValues: ReplyMessageValues = {
    comment: '',
    reason: null,
    fileList: null
  }

  const onSubmit = (values: ReplyMessageValues) => {
    addRemarkMessage({
      projectId,
      remarkId,
      file: values.fileList,
      message: {
        comment: values.comment,
        reason: values.reason,
        status: newStatus
      }
    })
  }

  const { formik } = useForm({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => onSubmit(values)
  })

  const { values: { fileList }, dirty } = formik

  useEffect(() => {
    onFormChange && onFormChange(dirty)
  }, [dirty])

  const removeFile = (fileToRemove: File) => {
    if (fileList) {
      const updatedFiles = Array.from(fileList).filter(file => file !== fileToRemove)
      const dataTransfer = new DataTransfer()
      updatedFiles.forEach(file => dataTransfer.items.add(file))
      const updatedFileList = dataTransfer.files
      formik.setFieldValue('fileList', updatedFileList)
    }
  }

  const handleClosereasonField = () => {
    setOpenReasonField(false)
    formik.setFieldValue('reason', '')
  }

  useEffect(() => {
    if (addRemarkMessageResponse.isSuccess) {
      closeReply()
      enqueueSnackbar('Статус замечания обновлен.', { variant: 'success' })
      dispatch(setAllowScrolling({ allowScrolling: { allowScrolling: true, mode: 'edit' } }))
      dispatch(onDrawerClose({ dirty: false }))
    }
  }, [addRemarkMessageResponse])

  return (
    <FormikProvider value={formik}>
      <Box component={Form}>
        <ReplyBackground spacing={2} px={1} py={2}>
          <Stack direction='row' spacing={1.5}>
            <Avatar src={profile.avatar} sx={{ height: '32px', width: '32px' }} />
            <Stack sx={{ textAlign: 'start' }}>
              <EllipsisText variant="body1" fontWeight={500} maxWidth='240px'>
                {formatName(profile.lastName, profile.firstName, profile.middleName)}
              </EllipsisText>
              <Stack direction='row' spacing={1} alignItems='center'>
                <RemarkStatusLabel color={remarkColorStatus[newStatus]}></RemarkStatusLabel>
                <Typography variant="body1" fontSize={14}>
                  {remarkStatusEnToRu[newStatus]}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <RemarkTextField
            placeholder="Здесь Вы можете написать сопроводительное сообщение"
            version="project"
            multiline
            name="comment" />
          {!openReasonField &&
            <Box mt={1}>
              <StyledButton fullWidth onClick={() => setOpenReasonField(true)}>
                <AddIcon fontSize="medium" />
                Добавить основание
              </StyledButton>
            </Box>}
          {openReasonField &&
            <Box>
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <SubtitleText>Основание</SubtitleText>
                <IconButton size="small" onClick={handleClosereasonField}>
                  <CancelIcon fontSize="small" color="disabled" />
                </IconButton>
              </Stack>
              <RemarkTextField
                sx={{ position: 'relative' }}
                placeholder="Текст основания"
                version="project"
                multiline
                name="reason" />
            </Box>}

          {fileList && fileList.length > 0 &&
            <Box>
              <SubtitleText>Вложения</SubtitleText>
              {Array.from(fileList).map((file) =>
                <Stack key={file.name} p={0.75}>
                  <Stack direction='row' alignItems='center' spacing={0.5}>
                    <IconButton size="small" color="error"
                      onClick={() => removeFile(file)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                    <EllipsisText variant="body1" fontSize={14}>{file.name}</EllipsisText>
                  </Stack>
                </Stack>
              )}
            </Box>
          }
          {(!fileList || fileList.length < 5) &&
            <Box mt={1}>
              <Tooltip title={<>Не более 5 файлов до 100 МБ каждый</>}>
                <StyledButton fullWidth role={undefined} tabIndex={-1}>
                  <AttachFileIcon fontSize="medium" />
                  <FileUploader />
                  Прикрепить вложение
                </StyledButton>
              </Tooltip>
            </Box>
          }
          <Stack direction='row' justifyContent='space-between' spacing={2} pt={3}>
            <Box position='relative' width='100%'>
              <FormButton variant="contained" fullWidth color='success' type="submit"
                disabled={addRemarkMessageResponse.isLoading}>
                Добавить
              </FormButton>
              {addRemarkMessageResponse.isLoading && (
                <CircularProgress
                  size={24}
                  color="success"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
            <Box width='100%'>
              <FormButton fullWidth variant="contained"
                onClick={closeReply}>
                Отменить
              </FormButton>
            </Box>
          </Stack>
        </ReplyBackground>
      </Box>
    </FormikProvider>
  )
}