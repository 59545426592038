import * as yup from 'yup'

export const validationSchema = (pages: number | null, existingTom: boolean) => yup.object({
  page: yup.mixed().test('is-valid-page', 'Неверный формат', function(value) {
    if (value === undefined || value === null || value === '') {
      return true
    }
    return yup.number().max(pages || 100000).min(1).isValidSync(value)
  }),
  comment: yup.string().trim().required('common:errors.required'),
  tom: existingTom ? yup.mixed().notRequired() : yup.number().required('common:errors.required')
})

