import { NoteMode } from "../../PragmaPdfViewer.types"

export interface NotesToolbarProps {
  selectedMode: NoteMode | null
  selectMode: (value: NoteMode) => void
}

export type ColorNotes = 'red' | 'redbg'
export interface HexColorNotes extends Record<ColorNotes, string> {}

export const colorNotes: HexColorNotes = {
  'red': '#F46B6B',
  'redbg': '#FFB1B126'
}