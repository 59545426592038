import { RefObject } from "react";
import { Annotation, AnnotationRects, NoteMode } from "../../../PragmaPdfViewer.types";

interface CheckIsSelectAnnotationProps {
  annotations: Annotation[],
  clientX: number,
  clientY: number,
  scale: number,
  canvasRef: RefObject<HTMLCanvasElement | null>
}

interface CommonSelectProps {
  rects: AnnotationRects,
  clientX: number,
  clientY: number,
  scale: number,
  canvasRef: RefObject<HTMLCanvasElement | null>
}

const checkIsSelectRectangle = ({ canvasRef, clientX, clientY, rects, scale }: CommonSelectProps): boolean => {
  const canvas = canvasRef.current
  if (!canvas) return false
  const left = rects.left * canvas.width
  const top = rects.top * canvas.height
  const width = rects.width * canvas.width
  const height = rects.height * canvas.height
  const borderWidth = 5 * scale
  return (
    (clientX >= left - borderWidth && clientX <= left + borderWidth && clientY >= top - borderWidth && clientY <= top + height + borderWidth) ||
    (clientX >= left + width - borderWidth && clientX <= left + width + borderWidth && clientY >= top - borderWidth && clientY <= top + height + borderWidth) ||
    (clientY >= top - borderWidth && clientY <= top + borderWidth && clientX >= left - borderWidth && clientX <= left + width + borderWidth) ||
    (clientY >= top + height - borderWidth && clientY <= top + height + borderWidth && clientX >= left - borderWidth && clientX <= left + width + borderWidth)
  )
}

const checkIsSelectArrow = ({ canvasRef, clientX, clientY, rects, scale }: CommonSelectProps): boolean => {
  const canvas = canvasRef.current
  if (!canvas) return false
  const x1 = rects.left * canvas.width
  const y1 = rects.top * canvas.height
  const x2 = (rects.left + rects.width) * canvas.width
  const y2 = (rects.top + rects.height) * canvas.height
  const squareA = y2 - y1
  const squareB = x1 - x2
  const squareC = x2 * y1 - x1 * y2
  const borderWidth = 5 * scale
  const minX = Math.min(x1, x2)
  const maxX = Math.max(x1, x2)
  const minY = Math.min(y1, y2)
  const maxY = Math.max(y1, y2)

  const distanceToLine = Math.abs(squareA * clientX + squareB * clientY + squareC) / Math.sqrt(squareA * squareA + squareB * squareB)
  const isWithinBoundingBox = clientX >= minX - borderWidth && clientX <= maxX + borderWidth &&
    clientY >= minY - borderWidth && clientY <= maxY + borderWidth

  return (isWithinBoundingBox && distanceToLine <= borderWidth)
}

export const checkIsSelectAnnotation = ({
  annotations,
  canvasRef,
  clientX,
  clientY,
  scale
}: CheckIsSelectAnnotationProps) => {
  const selected = annotations.findIndex(({ rects, type }) => {
    if (type === 'rectangle' || type === 'text') {
      return checkIsSelectRectangle({ rects, canvasRef, clientX, clientY, scale })
    }
    if (type === 'arrow') {
      return checkIsSelectArrow({ rects, canvasRef, clientX, clientY, scale })
    } else return false
  })
  if (selected !== -1) {
    return { index: selected, type: annotations[selected].type }
  } else return null
}

export const checkIsInsideSelectAnnotation = ({ annotations, canvasRef, clientX, clientY }: {
  annotations: Annotation[]
  canvasRef: RefObject<HTMLCanvasElement | null>
  clientX: number
  clientY: number
}) => {
  const canvas = canvasRef.current
  if (!canvas) return null
  const isInsideSelected = annotations.findIndex(({ rects, type }) => {
    const left = rects.left * canvas.width
    const top = rects.top * canvas.height
    const width = rects.width * canvas.width
    const height = rects.height * canvas.height
    if (type === 'rectangle' || type === 'text') {
      return clientX >= left && clientX <= left + width && clientY >= top && clientY <= top + height
    } else return false
  })
  if (isInsideSelected !== -1) {
    return { index: isInsideSelected, type: annotations[isInsideSelected].type }
  } else return null
}

export const checkIsMoveCursor = ({ annotations, selectedRect, canvasRef, scale, clientX, clientY }: {
  annotations: Annotation[],
  selectedRect: { index: number, type: NoteMode } | null,
  canvasRef: RefObject<HTMLCanvasElement | null>,
  scale: number,
  clientX: number,
  clientY: number,
}) => {
  const canvas = canvasRef.current
  if (!(canvas && selectedRect)) return false
  const { index, type } = selectedRect
  const rects = annotations[index].rects
  const borderWidth = 5 * scale

  if (type === 'rectangle') {
    return (
      clientX >= rects.left * canvas.width - borderWidth &&
      clientX <= (rects.left + rects.width) * canvas.width + borderWidth &&
      clientY >= rects.top * canvas.height - borderWidth &&
      clientY <= (rects.top + rects.height) * canvas.height + borderWidth
    )
  }
  if (type === 'text') {
    return checkIsSelectRectangle({ canvasRef, clientX, clientY, rects, scale })
  }
  if (type === 'arrow') {
    return checkIsSelectArrow({ canvasRef, clientX, clientY, rects, scale })
  } else return false
}

export const setDraggingRect = ({ annotations, selectedRect, canvasRef, deltaX, deltaY }: {
  annotations: Annotation[],
  selectedRect: { index: number, type: NoteMode },
  canvasRef: RefObject<HTMLCanvasElement | null>,
  deltaX: number
  deltaY: number
}) => {
  const canvas = canvasRef.current
  return annotations.map((annotation, index) => {
    if (index === selectedRect.index) {
      if (selectedRect.type === 'rectangle' || selectedRect.type === 'text') {
        const newLeft = Math.max(0, Math.min(annotation.rects.left + deltaX / canvas!.width, 1 - annotation.rects.width))
        const newTop = Math.max(0, Math.min(annotation.rects.top + deltaY / canvas!.height, 1 - annotation.rects.height))

        return {
          ...annotation,
          rects: {
            ...annotation.rects,
            left: newLeft,
            top: newTop,
          },
        }
      } else if (selectedRect.type === 'arrow') {
        const { left, top, width, height } = annotation.rects;
        const x1 = left
        const y1 = top
        const x2 = left + width
        const y2 = top + height

        let newX1 = x1 + deltaX / canvas!.width
        let newY1 = y1 + deltaY / canvas!.height
        let newX2 = x2 + deltaX / canvas!.width
        let newY2 = y2 + deltaY / canvas!.height

        if (newX1 < 0) {
          newX1 = 0
          newX2 = newX1 + width
        } else if (newX2 > 1) {
          newX2 = 1
          newX1 = newX2 - width
        }

        if (newX2 < 0) {
          newX2 = 0
          newX1 = newX2 - width
        } else if (newX1 > 1) {
          newX1 = 1
          newX2 = newX1 + width
        }

        if (newY2 < 0) {
          newY2 = 0
          newY1 = newY2 - height
        } else if (newY1 > 1) {
          newY1 = 1
          newY2 = newY1 + height
        }

        if (newY1 < 0) {
          newY1 = 0
          newY2 = newY1 + height
        } else if (newY2 > 1) {
          newY2 = 1
          newY1 = newY2 - height
        }

        return {
          ...annotation,
          rects: {
            left: newX1,
            top: newY1,
            width: width,
            height: height,
          },
        }
        /* const { left, top, width, height } = annotation.rects
        const x2 = left + width
        const y2 = top + height
        const newX1 = left + deltaX / canvas!.width
        const newY1 = top + deltaY / canvas!.height
        const newX2 = x2 + deltaX / canvas!.width
        const newY2 = y2 + deltaY / canvas!.height
        const boundedX1 = Math.max(0, Math.min(newX1, 1))
        const boundedY1 = Math.max(0, Math.min(newY1, 1))
        const boundedX2 = Math.max(0, Math.min(newX2, 1))
        const boundedY2 = Math.max(0, Math.min(newY2, 1))
        const boundedWidth = boundedX2 - boundedX1
        const boundedHeight = boundedY2 - boundedY1
      
        return {
          ...annotation,
          rects: {
            left: boundedX1,
            top: boundedY1,
            width: boundedWidth,
            height: boundedHeight,
          },
        } */
      }
    }
    return annotation
  })
}

export const setResizingRect = ({ annotations, selectedRect, canvasRef, resizeHandle, clientX, clientY }: {
  annotations: Annotation[]
  selectedRect: { index: number, type: NoteMode }
  canvasRef: RefObject<HTMLCanvasElement | null>
  resizeHandle: string
  clientX: number
  clientY: number
}) => {
  const canvas = canvasRef.current
  return annotations.map((annotation, index) => {
    if (index === selectedRect.index && annotation.type === 'arrow') {
      let { left, top, width, height } = annotation.rects

      if (resizeHandle === 'left') {
        const newLeft = clientX / canvas!.width
        const newTop = clientY / canvas!.height
        width = (left + width) - newLeft
        height = (top + height) - newTop
        left = newLeft
        top = newTop
      } else if (resizeHandle === 'right') {
        width = (clientX / canvas!.width) - left
        height = (clientY / canvas!.height) - top
      }

      return {
        ...annotation,
        rects: { left, top, width, height },
      }
    } else if (index === selectedRect.index && (annotation.type === 'rectangle' || annotation.type === 'text')) {
      let { left, top, width, height } = annotation.rects

      if (resizeHandle === 'left') {
        const newLeft = Math.min(clientX / canvas!.width, left + width)
        const newTop = Math.min(clientY / canvas!.height, top + height)
        width = Math.abs((left + width) - newLeft)
        height = Math.abs((top + height) - newTop)
        left = newLeft
        top = newTop
      } else if (resizeHandle === 'right') {
        width = Math.max(0, (clientX / canvas!.width) - left)
        height = Math.max(0, (clientY / canvas!.height) - top)
      }

      return {
        ...annotation,
        rects: { left, top, width, height },
      }
    }

    return annotation
  })
}