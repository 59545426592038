import { useState, MouseEvent } from 'react';
import { Annotation } from '../../../PragmaPdfViewer.types';
import { UseRectangleToolProps } from '../CanvasAnnotations.types';
import { colorNotes } from '../../NotesToolbar';
import { checkIsInsideSelectAnnotation, checkIsMoveCursor, checkIsSelectAnnotation, setDraggingRect, setResizingRect } from './common';

export const useRectangleTool = ({
  canvasRef,
  setAnnotations,
  annotations,
  rotate,
  scale,
  selectedRect,
  setSelectedRect,
  drawAnnotations,
  setDataTextBox,
  selectTextAnnotation
}: UseRectangleToolProps): {
  onRectangleMouseDown: (e: MouseEvent<HTMLCanvasElement>) => void
  onRectangleMouseMove: (e: MouseEvent<HTMLCanvasElement>) => void
  onRectangleMouseUp: (e: MouseEvent<HTMLCanvasElement>) => void
} => {
  const [isDrawing, setIsDrawing] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [isResizing, setIsResizing] = useState(false)
  const [resizeHandle, setResizeHandle] = useState<string | null>(null)
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 })
  const [currentRect, setCurrentRect] = useState<{
    x: number
    y: number
    width: number
    height: number
  } | null>(null)

  const onRectangleMouseDown = (e: MouseEvent) => {
    const canvas = canvasRef.current
    if (!(canvas && e.buttons === 1)) return

    const rect = canvas.getBoundingClientRect()
    const x = (e.clientX - rect.left)
    const y = (e.clientY - rect.top)

    const selected = checkIsSelectAnnotation(
      {
        annotations,
        canvasRef,
        clientX: x,
        clientY: y,
        scale
      }
    )

    const isInsideSelected = checkIsInsideSelectAnnotation({
      annotations,
      canvasRef,
      clientX: x,
      clientY: y
    })

    if (selected) {
      const { left, top, width, height } = annotations[selected.index].rects
      const handleSize = 10 * scale
      const isTopLeftHandle =
        Math.abs(x - left * canvas.width) <= handleSize && Math.abs(y - top * canvas.height) <= handleSize
      const isBottomRightHandle =
        Math.abs(x - (left + width) * canvas.width) <= handleSize &&
        Math.abs(y - (top + height) * canvas.height) <= handleSize
      if (isTopLeftHandle || isBottomRightHandle) {
        setResizeHandle(isTopLeftHandle ? 'left' : 'right')
        setSelectedRect(selected)
        setIsResizing(true)
        return
      }
      setSelectedRect(selected)
      setIsDragging(true)
      setStartPosition({ x, y })
    } else if (selectedRect && isInsideSelected && isInsideSelected.type === 'rectangle') {
      setIsDragging(true)
      setStartPosition({ x, y })
    } else if (isInsideSelected && isInsideSelected.type === 'text') {
      selectTextAnnotation(isInsideSelected.index)
    } else {
      setSelectedRect(null)
      setIsDrawing(true)
      setStartPosition({ x, y })
      setCurrentRect({ x, y, width: 0, height: 0 })
    }
  }

  const isMouseOverResizeHandle = (x: number, y: number, selectedRect: number | undefined) => {
    if (selectedRect === undefined) return false

    const { left, top, width, height } = annotations[selectedRect].rects
    const handleSize = 5 * scale
    if (canvasRef.current) {
      const isTopLeftHandle =
        Math.abs(x - left * canvasRef.current.width) <= handleSize && Math.abs(y - top * canvasRef.current.height) <= handleSize
      const isBottomRightHandle =
        Math.abs(x - (left + width) * canvasRef.current.width) <= handleSize &&
        Math.abs(y - (top + height) * canvasRef.current.height) <= handleSize

      return isTopLeftHandle || isBottomRightHandle
    }
  }

  const onRectangleMouseMove = (e: MouseEvent) => {
    const canvas = canvasRef.current
    if (!canvas) return

    const rect = canvas.getBoundingClientRect()
    const x = (e.clientX - rect.left)
    const y = (e.clientY - rect.top)
    const ctx = canvas.getContext('2d')

    if (isDrawing && currentRect) {
      const width = x - startPosition.x
      const height = y - startPosition.y
      setCurrentRect({ x: startPosition.x, y: startPosition.y, width, height })

      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        drawAnnotations()
        ctx.strokeStyle = colorNotes['red']
        ctx.lineWidth = 2 * scale
        ctx.strokeRect(startPosition.x, startPosition.y, width, height)
      }
    }

    else if (isDragging && selectedRect) {
      const dx = x - startPosition.x
      const dy = y - startPosition.y
      const updatedAnnotations = setDraggingRect({
        annotations,
        canvasRef,
        selectedRect,
        deltaX: dx,
        deltaY: dy
      })

      setAnnotations(updatedAnnotations)
      setStartPosition({ x, y })
    }

    else if (isResizing && selectedRect && resizeHandle) {
      const updatedAnnotations = setResizingRect({
        annotations,
        canvasRef,
        selectedRect,
        resizeHandle,
        clientX: x,
        clientY: y
      })
      setAnnotations(updatedAnnotations)
    }

    if (ctx) {
      let isOnBorder = false
      isOnBorder = checkIsSelectAnnotation({
        annotations,
        canvasRef,
        clientX: x,
        clientY: y,
        scale
      }) === null ? false : true
      let isInsideTextAnnotation = null
      isInsideTextAnnotation = checkIsInsideSelectAnnotation({
        annotations,
        canvasRef,
        clientX: x,
        clientY: y
      })
      canvas.style.cursor = isOnBorder ? 'pointer' : isInsideTextAnnotation?.type === 'text' ? 'text' : 'default'
    }
    if (selectedRect) {
      const isMove = checkIsMoveCursor({
        annotations,
        selectedRect,
        canvasRef,
        scale,
        clientX: x,
        clientY: y
      })
      if (isMove) canvas.style.cursor = 'move'
    }

    if (resizeHandle || isMouseOverResizeHandle(x, y, selectedRect?.index)) {
      canvas.style.cursor = 'nwse-resize'
    }
  }

  const onRectangleMouseUp = (e: MouseEvent) => {
    const canvas = canvasRef.current
    if (!canvas) return
    const rect = canvas.getBoundingClientRect()
    const x = (e.clientX - rect.left)
    const y = (e.clientY - rect.top)
    if (isDrawing && currentRect && startPosition.x !== x && startPosition.y !== y) {
      let { x, y, width, height } = currentRect
      if (width < 0) {
        x += width
        width = Math.abs(width)
      }
      if (height < 0) {
        y += height
        height = Math.abs(height)
      }
      const normalizedRect = {
        left: x / canvas.width,
        top: y / canvas.height,
        width: width / canvas.width,
        height: height / canvas.height,
      }
      const newAnnotation: Annotation = {
        rects: normalizedRect,
        rotate,
        type: 'rectangle'
      }
      setAnnotations(newAnnotation)
    }

    setIsDrawing(false)
    setIsDragging(false)
    setIsResizing(false)
    setCurrentRect(null)
    setResizeHandle(null)
  }

  return { onRectangleMouseDown, onRectangleMouseMove, onRectangleMouseUp }
}
