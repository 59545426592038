import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { colorNotes } from '../NotesToolbar/NotesToolbar.types';
import { CanvasAnnotationsProps, DataTextBox, UseRectangleToolProps } from './CanvasAnnotations.types';
import { useRectangleTool } from './hooks/useRectangleTool';
import { Annotation, AnnotationRects, DEFAULT_MARGIN, NoteMode } from '../../PragmaPdfViewer.types';
import { useArrowTool } from './hooks/useArrowTool';
import { useTextTool } from './hooks/useTextTool';
import { TextBox } from './CanvasAnnotations.styles';

export const CanvasAnnotations = ({
  width,
  height,
  scale,
  rotate,
  setAnnotations,
  annotations,
  annotationType,
}: CanvasAnnotationsProps) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const [selectedRect, setSelectedRect] = useState<{ index: number, type: NoteMode } | null>(null)
  const [dataTextBox, setDataTextBox] = useState<DataTextBox | null>(null)
  const [isEditing, setIsEditing] = useState(false)

  const drawAnnotations = useCallback(() => {
    const canvas = canvasRef.current
    const ctx = canvas?.getContext('2d')
    if (!ctx || !canvas) return
    
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    annotations.forEach((annotation, index) => {
      const x1 = annotation.rects.left * canvas.width
      const y1 = annotation.rects.top * canvas.height
      const x2 = (annotation.rects.left + annotation.rects.width) * canvas.width
      const y2 = (annotation.rects.top + annotation.rects.height) * canvas.height

      if (annotation.type === 'arrow') {
        drawArrow(ctx, x1, y1, x2, y2)
      }
      if (annotation.type === 'rectangle') {
        ctx.strokeStyle = colorNotes['red']
        ctx.lineWidth = 2 * scale
        ctx.strokeRect(x1, y1, x2 - x1, y2 - y1)
      }
      if (annotation.type === 'text') {
        drawTextBox(ctx, x1, y1, x2 - x1, y2 - y1, annotation.text)
      }
      //ctx.shadowColor = 'transparent'
      if (selectedRect && selectedRect.index === index) {
        drawResizeHandles(ctx, x1, y1, x2 - x1, y2 - y1)
      }
    })
  }, [annotations, selectedRect, scale, width, height])

  const drawResizeHandles = (ctx: CanvasRenderingContext2D, left: number, top: number, width: number, height: number) => {
    const handleSize = 10 * scale
    ctx.fillStyle = '#0044B4'
    ctx.fillRect(left - handleSize / 2, top - handleSize / 2, handleSize, handleSize)
    ctx.fillStyle = '#0044B4'
    ctx.fillRect(left + width - handleSize / 2, top + height - handleSize / 2, handleSize, handleSize)
    //ctx.shadowColor = '#000000d8'
    //ctx.shadowBlur = 13
  }
  
  const selectTextAnnotation = useCallback((newIndex: number) => {
      if (isEditing) {
        handleBlurTextarea()
      }
      const newTextBox = annotations[newIndex]
      if (newTextBox && newTextBox.type === 'text') {
        setDataTextBox({
          index: newIndex,
          text: newTextBox.text || '',
          left: newTextBox.rects.left,
          top: newTextBox.rects.top,
          width: newTextBox.rects.width,
          height: newTextBox.rects.height,
        })
        setSelectedRect({ index: newIndex, type: 'text' })
      }
    },
    [annotations]
  )

  const props: UseRectangleToolProps = {
    canvasRef,
    setAnnotations,
    annotations,
    scale,
    rotate,
    selectedRect,
    setSelectedRect: (value) => setSelectedRect(value),
    drawAnnotations,
    setDataTextBox,
    selectTextAnnotation
  }

  const { onRectangleMouseDown, onRectangleMouseMove, onRectangleMouseUp } = useRectangleTool(props)
  const { onArrowMouseDown, onArrowMouseMove, onArrowMouseUp, drawArrow } = useArrowTool(props)
  const { onTextMouseDown, onTextMouseMove, onTextMouseUp, drawTextBox } = useTextTool(props)

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Delete' && selectedRect !== null) {
        const updatedAnnotations = annotations.filter((_, index) => index !== selectedRect.index)
        setAnnotations(updatedAnnotations)
        setSelectedRect(null)
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [annotations, selectedRect, setAnnotations])

  const applyRotation = (rect: AnnotationRects, annotaionRotate: number, type: NoteMode): AnnotationRects => {
    const { left, top, width, height } = rect
    const canvas = canvasRef.current
    if (!canvas) return rect
    const totalRotation = (rotate - annotaionRotate + 360) % 360

    if (type === 'rectangle' || type === 'text') {
      switch (totalRotation) {
        case 90:
          return {
            left: canvas.width - top * canvas.width - height * canvas.width,
            top: left * canvas.height,
            width: height * canvas.width,
            height: width * canvas.height,
          }
        case 180:
          return {
            left: canvas.width - left * canvas.width - width * canvas.width,
            top: canvas.height - top * canvas.height - height * canvas.height,
            width: width * canvas.width,
            height: height * canvas.height,
          }
        case 270:
          return {
            left: top * canvas.width,
            top: canvas.height - left * canvas.height - width * canvas.height,
            width: height * canvas.width,
            height: width * canvas.height,
          }
        default:
          return {
            left: left * canvas.width,
            top: top * canvas.height,
            width: width * canvas.width,
            height: height * canvas.height,
          }
      }
    } else if (type === 'arrow') {
      const x1 = left
      const y1 = top
      const x2 = left + width
      const y2 = top + height

      switch (totalRotation) {
        case 90:
          return {
            left: canvas.width - y1 * canvas.width,
            top: x1 * canvas.height,
            width: (y1 - y2) * canvas.width,
            height: (x2 - x1) * canvas.height,
          }
        case 180:
          return {
            left: canvas.width - x1 * canvas.width,
            top: canvas.height - y1 * canvas.height,
            width: -(x2 - x1) * canvas.width,
            height: -(y2 - y1) * canvas.height,
          }
        case 270:
          return {
            left: y1 * canvas.width,
            top: canvas.height - x1 * canvas.height,
            width: (y2 - y1) * canvas.width,
            height: -(x2 - x1) * canvas.height,
          }
        default:
          return {
            left: x1 * canvas.width,
            top: y1 * canvas.height,
            width: (x2 - x1) * canvas.width,
            height: (y2 - y1) * canvas.height,
          }
      }
    }
    return rect
  }

  useEffect(() => {
    const canvas = canvasRef.current
    if (canvas && annotations.length !== 0) {
      const transformedAnnotations: Annotation[] = annotations.map((annotation) => {
        const { left, top, width, height } = applyRotation(annotation.rects, annotation.rotate, annotation.type)
        return {
          ...annotation,
          rects: {
            left: left / canvas.width,
            top: top / canvas.height,
            width: width / canvas.width,
            height: height / canvas.height,
          },
          rotate
        }
      })
      setAnnotations(transformedAnnotations)
    }
  }, [rotate])

  useEffect(() => {
    drawAnnotations()
  }, [annotations, scale, drawAnnotations, selectedRect])

  const handleMouseDown = (e: MouseEvent<HTMLCanvasElement>) => {
    switch (annotationType) {
      case 'rectangle':
        return onRectangleMouseDown(e)
      case 'arrow':
        return onArrowMouseDown(e)
      case 'highlight':
        return {}
      case 'text':
        return onTextMouseDown(e)
      default:
        return () => { }
    }
  }

  const handleMouseMove = (e: MouseEvent<HTMLCanvasElement>) => {
    switch (annotationType) {
      case 'rectangle':
        return onRectangleMouseMove(e)
      case 'arrow':
        return onArrowMouseMove(e)
      case 'highlight':
        return {}
      case 'text':
        return onTextMouseMove(e)
      default:
        return () => { }
    }
  }

  const handleMouseUp = (e: MouseEvent<HTMLCanvasElement>) => {
    switch (annotationType) {
      case 'rectangle':
        return onRectangleMouseUp(e)
      case 'arrow':
        return onArrowMouseUp(e)
      case 'highlight':
        return {}
      case 'text':
        return onTextMouseUp(e)
      default:
        return () => { }
    }
  }

  useEffect(() => {
    if (annotationType) {
      setSelectedRect(null)
    }
  }, [annotationType])

  useEffect(() => {
    if (dataTextBox && textareaRef.current) {
      setIsEditing(true)
      textareaRef.current.focus()
      const updatedAnnotations = annotations.map((annotation, i) =>
        i === dataTextBox.index ? { ...annotation, text: '' } : annotation)
      setAnnotations(updatedAnnotations)
    }
  }, [dataTextBox, textareaRef.current])

  const handleBlurTextarea = useCallback(() => {
    if (dataTextBox && isEditing) {
      setIsEditing(false)
      const updatedAnnotations = annotations.map((annotation, i) =>
        i === dataTextBox.index ? { ...annotation, text: dataTextBox.text } : annotation)
      setAnnotations(updatedAnnotations)
      setDataTextBox(null)
    }
  }, [dataTextBox, isEditing])

  return (
    <Box position='absolute' zIndex={2} height='100%' width='100%' padding={DEFAULT_MARGIN + 'px'}>
      <canvas
        width={width}
        height={height}
        ref={canvasRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        style={{ pointerEvents: 'auto', height: '100%', width: '100%' }}
      />
      {dataTextBox && canvasRef.current &&

        <TextBox
          key={dataTextBox.index}
          ref={textareaRef}
          value={dataTextBox.text}
          left={dataTextBox.left * canvasRef.current.width + DEFAULT_MARGIN}
          top={dataTextBox.top * canvasRef.current.height + DEFAULT_MARGIN}
          width={dataTextBox.width * canvasRef.current.width}
          height={dataTextBox.height * canvasRef.current.height}
          scale={scale}
          onChange={(e) => setDataTextBox((prev) => ({ ...prev!, text: e.target.value }))}
          onBlur={handleBlurTextarea}
        />
      }
    </Box>
  )
}