import { Button, Stack, Typography } from "@mui/material";
import styled from "styled-components";
import { RemarkColorLabeL } from "./Remarks.types";

export const StatusText = styled(Typography)`
  font-size: 11px;
  color: ${props => props.theme.palette.text.dark};
`
export const TextLink = styled(Typography)`
  color: ${props => props.theme.palette.primary.main};

  :hover {
    cursor: pointer;
  }
`

export const StatusLabel = styled.span<{ color: RemarkColorLabeL }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => props.color === 'lightgreen' && props.theme.palette.legends.successLight};
  background-color: ${props => props.color === 'blue' && props.theme.palette.legends.chartsBlue};
  background-color: ${props => props.color === 'yellow' && props.theme.palette.legends.chartsYellow};
  background-color: ${props => props.color === 'gray' && props.theme.palette.disabled};
  background-color: ${props => props.color === 'green' && props.theme.palette.legends.chartsGreen};
  background-color: ${props => props.color === 'red' && props.theme.palette.error.main};
`
export const RemarkContainer = styled(Stack) <{ selected: boolean }>`
  border-radius: 0px 32px 32px 32px;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  border: 1px solid #fff;
  border-color: ${props => props.selected && '#8CBBE8'};
  background-color: ${props => props.theme.palette.bg.white};
  background-color: ${props => props.selected && props.theme.palette.bg.lightBlue};
`
export const SubtitleText = styled(Typography)`
  font-size: 12px;
  color: ${props => props.theme.palette.secondary.gray};
`
export const MainText = styled(Typography)`
  text-align: start;
  overflow-wrap: anywhere;
  overflow: hidden;
`
export const FileNameText = styled(Typography)`
  font-size: 14px;
  text-align: start;
  overflow-wrap: anywhere;
  overflow: hidden;
  white-space: pre-wrap;
  color: ${props => props.theme.palette.primary.main};
`
export const ChangeStatusButton = styled(Button)<{selected: boolean}>`
  background-color: ${props => props.theme.palette.bg.shades};
  background-color: ${props => props.selected && props.theme.palette.bg.lightBlue};
  padding: 8px;
  border-radius: 16px;
  justify-content: flex-start;
  height: auto;
  box-sizing: content-box;

  :hover {
    background-color: ${props => props.theme.palette.bg.lightBlue}; 
  }
`
export const RemarkStatusLabel = styled.span <{ color: RemarkColorLabeL }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => props.color === 'lightgreen' && props.theme.palette.legends.successLight};
  background-color: ${props => props.color === 'blue' && props.theme.palette.legends.chartsBlue};
  background-color: ${props => props.color === 'yellow' && props.theme.palette.legends.chartsYellow};
  background-color: ${props => props.color === 'gray' && props.theme.palette.disabled};
  background-color: ${props => props.color === 'green' && props.theme.palette.legends.chartsGreen};
  background-color: ${props => props.color === 'red' && props.theme.palette.error.main};
`
export const MessageWrapper = styled(Stack) <{ $bg: string }>`
  border-radius: 16px;
  padding: 0;
  padding: ${props => (props.$bg === 'shades' || props.$bg === 'blue') && '8px'};
  background-color: ${props => props.$bg === 'shades' && props.theme.palette.bg.shades};
  background-color: ${props => props.$bg === 'blue' && props.theme.palette.bg.lightBlue};
`
export const EllipsisText = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
export const ExpandButton = styled(Button)`
  text-transform: none;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 5px 8px;
  height: auto;

  :hover {
    background-color: ${props => props.theme.palette.bg.lightBlue}; 
  }
`