import { Box, IconButton, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import styled from "styled-components";
import { ColorNotes } from "./NotesToolbar.types";

export const NotesToolbarWrapper = styled(Stack)`
    @media(min-width: 1600px)  {
    min-width: 202px;
  }
`
export const ColoredCircle = styled(Box)<{color: ColorNotes}>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${props => props.color === 'red' && '#F46B6B'};
`
export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  background-color: #1976D214;
`
export const NotesButton = styled(ToggleButton)`
  padding: 4px;
  border: 1px solid #0044b41a;
  border-radius: 8px;

    &.Mui-selected {
    background-color: #0044B4;
    color: #fff;

    :hover {
      background-color: #0044B4;
    }
  }
`